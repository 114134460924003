import React, { useState } from "react";
import { Box, Flex, HStack, Heading, Image, Button, Text, VStack } from '@chakra-ui/react';
import ReviewFeedbackIcon from "../Images/ReviewFeedbackIcon.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from './config';

const CreateUserAttempt = ({ subjectName, paperName, numOfQuestionsLeft, mark, grade, totalMark, inProgress, progressPercentage, user_paper_attempt_guid }) => {

    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleAccessPaperAttempt = async (user_paper_attempt_guid) => {
        if (loading) return;
      
        try {
          setLoading(true);
          setError(null);
      
          const paperDetailResponse = await axios.get(
            `${BASE_API_URL}/api/v1/user-paper-attempts/detail/${user_paper_attempt_guid}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          );
      
          const { subject_name, paper_name, paper_code, time_hour, time_min } = paperDetailResponse.data;
          const formattedPaperName = `Paper ${paper_name.split(' ')[1]}${paper_code.trim().slice(-1)} ${paper_name.split(' ').slice(2).join(' ')}`;
      
          const questionsResponse = await axios.get(
            `${BASE_API_URL}/api/v1/user-paper-attempts/questions-with-attempt/${user_paper_attempt_guid}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          );

          setQuestions(questionsResponse.data);

          navigate('/my-papers/solve-paper', {
            state: {
              questions: questionsResponse.data,
              paper_Guid: user_paper_attempt_guid,
              subject_name,
              formattedPaperName,
              time_hour,
              time_min,
            },
          });
        } catch (error) {
          console.error("Error fetching past paper questions or creating attempt:", error);
          setError("There was an error trying to access this paper. Please try again");
        } finally {
          setLoading(false);
        }
      };

    return (
        <Box
            width={{ base: "100%", sm: "280px", md: "250px", lg: "280px" }}
            maxWidth="100%"
            height="280px"
            borderRadius="14px"
            backgroundColor="#ffffff"
            boxShadow="1px 1px 4.7px rgba(0, 0, 0, 0.14)"
            overflow="hidden"
            margin="auto" 
            
        >
            {/* Top section */}
            <Box
                background={inProgress ? "#4BFF5C" : "#4BFF5C"}
                padding="44px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderBottom="0.2px solid #E7EAE8"
                cursor={loading ? "not-allowed" : "pointer"}
                opacity={loading ? 0.7 : 1}
                onClick={() => handleAccessPaperAttempt(user_paper_attempt_guid)}
            >
                {inProgress ? (
                    <Text color="#021A04" fontSize="14px" fontWeight="400">
                        In Progress...
                    </Text>
                ) : (
                    <Box padding="4px" borderRadius="8px" background="#021A04">
                        <Text color="#ffffff" fontSize="14px" fontWeight="400">
                            Completed
                        </Text>
                    </Box>
                )}
            </Box>

            {/* Bottom section */}
            <Box padding="16px" mb = {18} >
                <VStack alignItems="left" mt="8px" spacing={2} mb={12}>
                    <Heading color="#021A04" fontSize="16px" fontWeight="500">
                        {subjectName}
                    </Heading>
                    <Heading color="#535953" fontSize="12px" fontWeight="400">
                        {paperName || ""}
                    </Heading>
                </VStack>

                {/* Progress bar for In Progress */}
                {inProgress ? (
                    <Flex mt="12px" justifyContent="space-between" alignItems="center">
                        <Text color="#535953" fontSize="12px" fontWeight="400">
                            {numOfQuestionsLeft} questions left
                        </Text>
                        <Flex alignItems="center" width="120px">
                            <Box width="100%" height="5px" bg="#E7EAE8" borderRadius="24px" overflow="hidden">
                                <Box
                                    width={`${progressPercentage}%`}
                                    height="5px"
                                    bg="#4BFF5C"
                                    borderRadius="24px"
                                />
                            </Box>
                            <Text color="#535953" fontSize="11px" fontWeight="400" ml="8px">
                                {`${progressPercentage}%`}
                            </Text>
                        </Flex>
                    </Flex>
                ) : (
                    <HStack justifyContent="space-between" mt="16px" >
                        <Flex flexDirection="column">
                            <Heading color="#535953" fontSize="12px" fontWeight="400">
                                {`Mark: ${mark} / ${totalMark}`}
                            </Heading>
                            <Heading color="#535953" fontSize="12px" fontWeight="400">
                                {`Grade: ${grade || "N/A"}`}
                            </Heading>
                        </Flex>
                        <Flex flexDirection="row" alignItems="center">
                            <Heading color="#535953" fontSize="12px" fontWeight="400" mr="8px">
                                Review Feedback
                            </Heading>
                            <Button variant = "unstyled">
                                <Image src={ReviewFeedbackIcon} alt="reviewIcon" />
                            </Button>
                        </Flex>
                    </HStack>
                )}
                {error && (
                    <Text color="red" mt={4} fontSize="12px">
                        {error}
                    </Text>
                )}
            </Box>
        </Box>
    );
};

export default CreateUserAttempt;
